<script>
	import { onMount } from 'svelte'
	import { grayMapStyle } from '../utils/getGoogleMaps'

	/**
	 * @type {import('./Map.svelte').MarkerData[]}
	 */
	export let markers = []
	/**
	 * @type {import('./Map.svelte').Location}
	 */
	export let center
	/**
	 * @type {number}
	 */
	export let zoom

	/**
	 * @type {typeof google.maps}
	 */
	export let googleMaps

	/**
	 * @type {HTMLDivElement}
	 */
	let element

	/**
	 * @type {google.maps.Map<HTMLDivElement>}
	 */
	let map
	/**
	 * @type {google.maps.Marker[]}
	 */
	let placedMarkers = []

	onMount(() => {
		const styledMapType = new googleMaps.StyledMapType(grayMapStyle)
		map = new googleMaps.Map(element, {
			center: {
				lat: center.latitude,
				lng: center.longitude,
			},
			zoom: zoom,
			disableDefaultUI: true
		})
		map.mapTypes.set('styled_map', styledMapType)
		map.setMapTypeId('styled_map')
	})

	$: {
		if (map) {
			if (zoom !== map.getZoom()) {
				map.setZoom(zoom)
			}
			map.panTo(new google.maps.LatLng(center.latitude, center.longitude))
		}
	}

	$: {
		if (map) {
			placedMarkers.forEach((marker) => marker.setMap(null))
			const newPlacedMarkers = markers.map(
				(markerData) => {
					const size = markerData.small ? new google.maps.Size(40, 40) : new google.maps.Size(80, 80)
					const marker = new googleMaps.Marker({
						map,
						title: markerData.title,
						position: {
							lat: markerData.latitude,
							lng: markerData.longitude,
						},
						icon: {
							url: markerData.icon,
							scaledSize: size,
							origin: new google.maps.Point(0, 0),
							anchor: new google.maps.Point(size.width / 2, size.height),
						},
					})
					if (markerData.onClick) {
						marker.addListener('click', markerData.onClick)
					}
					return marker
				},
			)
			placedMarkers = newPlacedMarkers
		}
	}
</script>

<div bind:this={element} class="map-in" />
